<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Customer Service</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Customer Service</p>
            <div class="description-container">
                <p class="subtitle">Job Descriptions</p>
                <ul class="requirement-list">
                    <li class="list-details"> Attracting potential customers by answering product and service questions; suggesting information about other products and services.</li>
                    <li class="list-details"> Opening customer accounts by recording account information.</li>
                    <li class="list-details"> Resolving product or service problems by clarifying the customer's complaint; determining the cause of the problem; selecting and explaining the best solution to solve the problem; expediting correction or adjustment; following up to ensure resolution.</li>
                    <li class="list-details"> Preparing product or service reports by collecting and analyzing customer information.</li>
                    <li class="list-details"> Contributing to team effort by accomplishing related results as needed.</li>
                    <li class="list-details"> Follow communication procedures, guidelines and policies.</li>
                    <li class="list-details"> Taking the extra mile to engage customers.</li>
                </ul>
            </div>

            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;
        .list-details {
            display: list-item;
            margin-bottom: 0.7%;
            list-style-type:disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}

.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
